<template>
  <div class="navbar-logo" :class="'is-' + name">
    <nuxt-link
      :to="{ name: 'home' }"
      :title="globalConfig?.siteName"
      class="navbar-logo-inner"
    >
      <div class="navbar-logo-text">{{ globalConfig?.siteName }}</div>
    </nuxt-link>
  </div>
</template>

<script setup lang="ts">
const globalConfig = await useGlobalConfig()

const { name } = useCurrentSite()
</script>

<style lang="scss">
.navbar-logo {
  &.is-haltefest {
    .navbar-logo-text {
      font-size: 0.8em;
    }
  }
  &.is-eb {
    .navbar-logo-text {
      font-size: 0.75em;
    }
  }
  &.is-creced {
    .navbar-logo-text {
      text-transform: none;
    }
  }
}
</style>
