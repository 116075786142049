type ValidSiteName = 'eb' | 'creced' | 'haltefest'

type CurrentSite = {
  name: ValidSiteName
}

export default function useCurrentSite(): globalThis.Readonly<CurrentSite> {
  const config = useRuntimeConfig()

  return readonly({
    name: config.public.site as ValidSiteName,
  })
}
