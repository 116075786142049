<template>
  <div
    class="alert alert-dismissible mb-0"
    :class="classes"
    @mouseenter="onMouseEnter"
  >
    <div class="py-1" v-html="message" />
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
    ></button>
  </div>
</template>

<script lang="ts" setup>
import type { DrupalMessageType } from '~/composables/useDrupalMessages'

const emit = defineEmits(['close'])

const props = defineProps<{
  type?: DrupalMessageType
  duration?: number
  message: string
  isError?: boolean
  noClose?: boolean
}>()

let timeout: any = null

const classes = computed(() => {
  if (props.type === 'error' || props.isError) {
    return 'alert-danger'
  } else if (props.type === 'status') {
    return 'alert-success'
  } else if (props.type === 'warning') {
    return 'alert-warning'
  }
  return ''
})

function onMouseEnter() {
  window.clearTimeout(timeout)
}

onMounted(() => {
  if (props.duration) {
    timeout = setTimeout(() => {
      emit('close')
    }, props.duration)
  }
})

onBeforeUnmount(() => {
  window.clearTimeout(timeout)
})
</script>
