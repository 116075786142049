<template>
  <footer class="container">
    <FooterActions />
    <div class="footer">
      <div class="footer-logo">{{ globalConfig.siteName }}</div>
      <nav class="footer-menu">
        <ul class="list-unstyled">
          <li v-for="(menuItem, index) in footerLinks" :key="index">
            <DrupalLink :to="menuItem.link.url?.path">{{
              menuItem.link.label
            }}</DrupalLink>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</template>

<script setup lang="ts">
const globalConfig = await useGlobalConfig()

const initData = await useInitData()
const footerLinks = initData.value.footerMenuLinks
</script>

<style lang="scss">
@import '~/assets/scss/variables';
.footer {
  padding: $size-3 0;
  border-top: 1px solid $gray-500;
  font-size: 1rem;
  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
  }
  @include media-breakpoint-up(xl) {
    padding: $size-4 0;
    font-size: $h6-font-size;
  }
}

.footer-menu {
  padding-bottom: 4rem;
  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -1em;
    li {
      padding: 0 1em;
    }
  }

  a {
    color: $gray-600;
    &:hover {
      color: $gray-800;
    }
  }
}

.footer-logo {
  font-weight: $font-weight-bold;
}
</style>
