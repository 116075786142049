<template>
  <component :is="tag" class="navbar-menu" :class="levelClass">
    <slot name="before"></slot>
    <ul class="navbar-menu-list" :class="levelClass">
      <li
        v-for="(menuItem, index) in links"
        :key="index"
        class="navbar-menu-list-item"
        :class="levelClass"
      >
        <slot
          :id="menuItem.link.label"
          name="link"
          :link="menuItem.link"
          :index="index"
          :link-props="getLinkPropsA(menuItem.link)"
        >
          <nuxt-link v-bind="getLinkProps(menuItem.link)">
            <span>{{ menuItem.link.label }}</span>
          </nuxt-link>
        </slot>
        <slot
          v-if="menuItem.subtree && menuItem.subtree.length"
          :id="menuItem.link.label"
          :label="menuItem.link.label"
          :level="level + 1"
          :link="menuItem.link?.url?.path"
          :links="menuItem.subtree"
        />
      </li>
    </ul>
  </component>
</template>

<script setup lang="ts">
import type {
  MainMenuLinkTreeFirstFragment,
  MainMenuLinkTreeFragment,
} from '#graphql-operations'

// Props
const props = defineProps({
  tag: {
    type: String,
    default: 'div',
  },
  level: {
    type: Number,
    required: true,
  },
  links: {
    type: Array as PropType<MainMenuLinkTreeFirstFragment[]>,
    default: () => {
      return []
    },
  },
})

// Computed
const levelClass = computed(() => {
  return 'is-level-' + props.level
})

// Methods
const getLinkProps = function (link: MainMenuLinkTreeFragment['link']) {
  return {
    to: link?.url?.path,
    class: ['navbar-menu-link', levelClass.value],
  }
}
const getLinkPropsA = function (link: MainMenuLinkTreeFragment['link']) {
  return {
    href: link?.url?.path,
    class: ['navbar-menu-link', levelClass.value],
  }
}
</script>
