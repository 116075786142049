<template>
  <component :is="component" v-bind="props" @click="onClick">
    <slot :is-active="isActive" :is-external="isExternal"></slot>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
const EXTERNAL_URL_EXTENSIONS = ['jpg', 'png', 'svg', 'pdf']

function getUrlExtension(url: string): string {
  try {
    if (!url.includes('.')) {
      return ''
    }
    const parts = url.split(/[#?]/)
    return parts[0].split('.').pop()?.trim() || ''
  } catch (_e) {
    return ''
  }
}

export default defineComponent({
  name: 'DrupalLink',
  props: {
    to: {
      type: String,
      default: '',
    },
    openInSameWindow: {
      type: Boolean,
      default: false,
    },
    isLink: {
      type: Boolean,
      default: false,
    },
    isDrupalRouted: {
      type: Boolean,
      default: false,
    },
    activeClass: {
      type: String,
      default: 'nuxt-link-exact-active',
    },
  },
  computed: {
    href(): string {
      if (!this.to) {
        return ''
      }

      if (this.to.startsWith('www')) {
        return 'https://' + this.to
      }

      return this.to
    },
    component(): string {
      return this.href ? 'a' : 'div'
    },
    urlExtension(): string {
      return getUrlExtension(this.href).toLowerCase()
    },
    isExternal(): boolean {
      if (this.isDrupalRouted || this.href.startsWith('http')) {
        return true
      }
      if (this.urlExtension) {
        return (
          EXTERNAL_URL_EXTENSIONS.includes(this.urlExtension) ||
          this.href.includes(this.urlExtension)
        )
      }
      return false
    },

    props(): Record<string, string | string[]> {
      const props: Record<string, any> = {
        class: [],
      }
      if (this.href) {
        props.href = this.href

        if (this.isExternal && !this.openInSameWindow) {
          props.target = '_blank'
        }
      }

      if (this.isLink) {
        props.class.push('link')
        props.class.push(this.isExternal ? 'is-external' : 'is-internal')
      }

      if (this.isActive) {
        props.class.push(this.activeClass)
      }

      return props
    },
    isActive(): boolean {
      return this.href === this.$route.path
    },
  },
  methods: {
    onClick(e: Event) {
      if (this.isExternal) {
        return
      }

      e.preventDefault()
      this.$router.push(this.href)
    },
  },
})
</script>
