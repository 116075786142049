<template>
  <div>
    <!-- This will capture any errors that might happen when rendering the header. -->
    <!-- We don't want to trigger another error on the error page. -->
    <NuxtErrorBoundary>
      <PageHeader />
    </NuxtErrorBoundary>
    <div class="page-content mt-5">
      <Container>
        <h1>{{ title }}</h1>
        <h2>{{ titleLight }}</h2>
        <p>{{ text }}</p>
        <a v-if="goToHome" :href="frontUrl" class="button is-primary">{{
          goToHome
        }}</a>
      </Container>
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * IMPORTANT: Do not use $texts here because the $texts plugin might not be
 * available because the plugin didn't initialize, which would again cause
 * an error.
 */
import type { PropType } from 'vue'

const route = useRoute()

interface ErrorMessages {
  accessDeniedTitle: string
  accessDeniedText: string
  pageNotFoundTitle: string
  pageNotFoundText: string
  serverErrorTitle: string
  goToHome: string
}

const ERRORS: Record<string, ErrorMessages> = {
  de: {
    accessDeniedTitle: 'Zugriff verweigert',
    accessDeniedText: 'Sie haben keinen Zugriff auf diese Seite.',
    pageNotFoundTitle: 'Seite nicht gefunden',
    pageNotFoundText:
      'Die von Ihnen angeforderte Seite konnte nicht gefunden werden. Benutzen Sie unsere Suchfunktion um den gewünschten Inhalt zu finden.',
    serverErrorTitle: 'Seite nicht verfügbar',
    goToHome: 'Zur Startseite',
  },
  fr: {
    accessDeniedTitle: 'Accès refusé',
    accessDeniedText: "Vous n'avez pas accès à cette page.",
    pageNotFoundTitle: 'Page introuvable',
    pageNotFoundText:
      "La page que vous avez demandée n'a pas pu être trouvée. Utilisez notre fonction de recherche pour trouver le contenu que vous recherchez.",
    serverErrorTitle: 'Page non disponible',
    goToHome: 'Aller à la page d’accueil',
  },
  it: {
    accessDeniedTitle: 'Accesso negato',
    accessDeniedText: 'Non hai accesso a questa pagina.',
    pageNotFoundTitle: 'Pagina non trovata',
    pageNotFoundText:
      'La pagina che hai richiesto non è stata trovata. Usa la nostra funzione di ricerca per trovare il contenuto desiderato.',
    serverErrorTitle: 'Pagina non disponibile',
    goToHome: 'Vai alla homepage',
  },
  es: {
    accessDeniedTitle: 'Acceso denegado',
    accessDeniedText: 'No tienes acceso a esta página.',
    pageNotFoundTitle: 'Página no encontrada',
    pageNotFoundText:
      'No se ha podido encontrar la página solicitada. Utilice nuestra función de búsqueda para encontrar el contenido que busca.',
    serverErrorTitle: 'Error del servidor',
    goToHome: 'Ir a la página de inicio',
  },
}

type ErrorObject = {
  url?: string
  statusCode?: number
  statusMessage?: string
  message?: string
  stack?: string
}

const language = computed<string>(() => {
  const v = route.params.language
  if (v) {
    if (typeof v === 'string') {
      return v
    }
  }

  const config = useRuntimeConfig()
  const fallbackLanguage =
    config.public.languageNegotiation.availableLanguages[0]

  return fallbackLanguage
})

const texts = computed<ErrorMessages>(() => {
  if (language.value && ERRORS[language.value]) {
    return ERRORS[language.value]
  }
  return ERRORS.de
})

const props = defineProps({
  error: Object as PropType<ErrorObject>,
})

const title = computed(() => {
  if (props.error?.statusCode === 403) {
    return texts.value.accessDeniedTitle
  } else if (props.error?.statusCode === 404) {
    return texts.value.pageNotFoundTitle
  }

  return texts.value.serverErrorTitle
})

const titleLight = computed(() => {
  if (props.error?.statusCode) {
    return 'Error ' + props.error.statusCode
  }
  return 'Error'
})

const text = computed(() => {
  if (props.error?.statusCode === 403) {
    return texts.value.accessDeniedText
  } else if (props.error?.statusCode === 404) {
    return texts.value.pageNotFoundText
  }

  return props.error?.message
})

const goToHome = computed(() => {
  return texts.value.goToHome
})

const frontUrl = computed(() => {
  return '/' + language.value
})
</script>
