
import type { NuxtSvgSpriteSymbol } from './runtime'
type SymbolImport = {
  import: () => Promise<string>
  attributes: Record<string, string>
}
export const SYMBOL_IMPORTS: Record<NuxtSvgSpriteSymbol, SymbolImport> = {
'books': { import: () => import('/home/library/www/frontend/assets/icons/books.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 1200 1200","xmlns":"http://www.w3.org/2000/svg","id":"books"} },
  'books2': { import: () => import('/home/library/www/frontend/assets/icons/books2.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 1200 1200","xmlns":"http://www.w3.org/2000/svg","id":"books2"} },
  'comments': { import: () => import('/home/library/www/frontend/assets/icons/comments.svg?raw').then(v => v.default), attributes: {"fill":"#000000","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","version":"1.1","x":"0px","y":"0px","viewBox":"0 0 128 128","style":"enable-background:new 0 0 128 128;","xml:space":"preserve","id":"comments"} },
  'library2': { import: () => import('/home/library/www/frontend/assets/icons/library2.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 1200 1200","xmlns":"http://www.w3.org/2000/svg","id":"library2"} },
  'magazine': { import: () => import('/home/library/www/frontend/assets/icons/magazine.svg?raw').then(v => v.default), attributes: {"fill":"#000000","xmlns":"http://www.w3.org/2000/svg","data-name":"Layer 1","viewBox":"0 0 100 100","x":"0px","y":"0px","id":"magazine"} },
  'menu': { import: () => import('/home/library/www/frontend/assets/icons/menu.svg?raw').then(v => v.default), attributes: {"width":"32","height":"32","viewBox":"0 0 32 32","fill":"#ffffff","xmlns":"http://www.w3.org/2000/svg","id":"menu"} },
  'new-testament': { import: () => import('/home/library/www/frontend/assets/icons/new-testament.svg?raw').then(v => v.default), attributes: {"fill":"#000000","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 48 48","id":"new-testament"} },
  'old-testament': { import: () => import('/home/library/www/frontend/assets/icons/old-testament.svg?raw').then(v => v.default), attributes: {"fill":"#000000","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 48 48","id":"old-testament"} },
  'people': { import: () => import('/home/library/www/frontend/assets/icons/people.svg?raw').then(v => v.default), attributes: {"fill":"#000000","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 48 48","id":"people"} },
  'poem': { import: () => import('/home/library/www/frontend/assets/icons/poem.svg?raw').then(v => v.default), attributes: {"fill":"#000000","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 128 128","x":"0px","y":"0px","id":"poem"} },
  'question': { import: () => import('/home/library/www/frontend/assets/icons/question.svg?raw').then(v => v.default), attributes: {"fill":"#000000","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 48 48","id":"question"} },
  'search': { import: () => import('/home/library/www/frontend/assets/icons/search.svg?raw').then(v => v.default), attributes: {"fill":"#000000","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 48 48","id":"search"} },
  'steps': { import: () => import('/home/library/www/frontend/assets/icons/steps.svg?raw').then(v => v.default), attributes: {"fill":"#000000","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","version":"1.1","x":"0px","y":"0px","viewBox":"0 0 100 100","style":"enable-background:new 0 0 100 100;","xml:space":"preserve","id":"steps"} },
  'table': { import: () => import('/home/library/www/frontend/assets/icons/table.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","xml:space":"preserve","viewBox":"0 0 100 100","id":"table"} },
  'topics': { import: () => import('/home/library/www/frontend/assets/icons/topics.svg?raw').then(v => v.default), attributes: {"fill":"#000000","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 48 48","id":"topics"} },
}
  