<template>
  <div class="breadcrumb-container bg-light">
    <nav aria-label="breadcrumb" class="container">
      <ol
        v-if="linksComputed.length > 1"
        ref="list"
        class="breadcrumb"
        itemscope
        itemtype="http://schema.org/BreadcrumbList"
      >
        <li
          v-for="(link, index) in linksComputed"
          :key="index"
          itemprop="itemListElement"
          itemscope
          itemtype="http://schema.org/ListItem"
          class="breadcrumb-item"
        >
          <component :is="link.tag" v-bind="link.props" itemprop="item">
            <span itemprop="name">
              {{ link.title }}
            </span>
          </component>
          <meta itemprop="position" :content="`${index + 2}`" />
        </li>
      </ol>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import type { BreadcrumbFragment } from '#graphql-operations'
import { NuxtLink } from '#components'

const list = ref<HTMLOListElement | null>(null)

const props = defineProps<{
  links: BreadcrumbFragment[]
  language?: any
}>()

const linksComputed = computed(() => {
  return props.links.map((link) => {
    const { title, url } = link
    const to = url?.path
    return {
      tag: to ? NuxtLink : 'span',
      props: to ? { to } : {},
      title,
    }
  })
})
</script>
