<template>
  <transition name="appear">
    <!-- First level -->
    <menu-level
      v-show="visible"
      tag="nav"
      :level="1"
      :links="links"
      :class="{ 'has-menu-open': !!modelValue }"
    >
      <template #link="linkProps1">
        <!-- Custom link for first level. -->
        <!-- We dont want to trigger a route navigation on click, setting an empty event name on nuxt-link does that. -->
        <a
          v-bind="linkProps1.linkProps"
          :class="[
            linkProps1.linkProps.class,
            { 'is-open': modelValue === linkProps1.id },
          ]"
          @click.prevent.stop="openFirstLevel(linkProps1.id)"
          @mouseenter="onMouseEnter(linkProps1.id)"
          @mouseleave="hovering = ''"
        >
          <div class="navbar-menu-icon">
            <SpriteSymbol :name="getIcon(linkProps1.link)" />
          </div>
          <span>{{ linkProps1.link.label }}</span>
        </a>
      </template>

      <!-- Second Level -->
      <template #default="props1">
        <transition :name="secondLevelTransition">
          <menu-level
            v-if="modelValue"
            v-show="modelValue === props1.id"
            v-bind="props1"
          >
            <template #before>
              <h2 class="navbar-menu-title is-level-2">
                <nuxt-link :to="props1.link">{{ props1.label }}</nuxt-link>
              </h2>
            </template>
            <!-- Third Level -->
            <template #default="props2">
              <menu-level v-bind="props2" />
            </template>
          </menu-level>
        </transition>
      </template>
    </menu-level>
  </transition>
</template>

<script setup lang="ts">
import type { MainMenuLinkTreeFirstFragment } from '#graphql-operations'

// Data
const secondLevelTransition = ref('')
const hovering = ref('')
const timeout = ref(0)

// Props
const props = defineProps({
  menuLinks: {
    type: Array as PropType<MainMenuLinkTreeFirstFragment[] | null>,
    default: () => [],
  },
  isMobile: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
    default: '',
  },
  visible: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue'])

// Computed
const links = computed(() => {
  // TODO: Remove these menu items.
  const filter = ['Buscar', 'Suchen', 'Login']
  return props.menuLinks?.filter((v: any) => !filter.includes(v.label))
})

// Methods
const openFirstLevel = function (id: string) {
  clearTimeout(timeout.value)
  // If the clicked link is the same as the one currently open, close the
  // menu.
  const firstLevel = props.modelValue === id && props.isMobile ? '' : id

  // The transition for the second level should only happen when no other
  // second level is already open.
  if (props.isMobile) {
    secondLevelTransition.value =
      !props.modelValue || !firstLevel ? 'appear' : ''
  } else {
    secondLevelTransition.value =
      !props.modelValue || !firstLevel ? 'appear' : ''
  }
  nextTick(() => {
    if (!import.meta.server) {
      emit('update:modelValue', firstLevel)
    }
  })
}

const getIcon = function (link: any) {
  if (link.icon) {
    switch (link.icon) {
      case 'IconOldTestament':
        return 'old-testament'
      case 'IconNewTestament':
        return 'new-testament'
      case 'IconTopics':
        return 'topics'
      case 'IconPeople':
        return 'people'
      case 'IconQuestion':
        return 'question'
      case 'IconComments':
        return 'comments'
      case 'IconSteps':
        return 'steps'
      case 'IconTable':
        return 'table'
      case 'IconMagazine':
        return 'magazine'
    }

    // @ts-ignore
    return link.icon
  }
  return 'topics'
}

const onMouseEnter = function (id: string) {
  if (props.isMobile) {
    return
  }
  hovering.value = id
  clearTimeout(timeout.value)
  timeout.value = window.setTimeout(() => {
    if (hovering.value && hovering.value !== props.modelValue) {
      openFirstLevel(hovering.value)
      hovering.value = ''
    }
  }, 250)
}
</script>
