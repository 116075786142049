<template>
  <div class="navbar-bible">
    <BButton pill variant="primary" size="sm" to="/es/bible/RVR60/s-mateo/1">
      <BookIcon /> <span> {{ $texts('bible', 'Bibel') }}</span>
    </BButton>
  </div>
</template>

<script setup lang="ts">
// https://icones.js.org/collection/bi?s=book
import BookIcon from '~icons/bi/book'

const { $texts } = useNuxtApp()
</script>

<style lang="scss">
@import '~/assets/scss/variables';
.navbar-bible {
  padding-right: 1rem;

  .btn {
    min-width: 40px;
    height: 40px;
    padding: 0 0.75em;
    display: flex;
    align-items: center;

    span {
      display: inline;
      margin-left: 0.25rem;
      @include media-breakpoint-up(lg) {
        display: none;
      }
      @include media-breakpoint-up(xl) {
        display: inline;
        margin-left: 0.25rem;
      }
    }

    svg {
      width: 1.25em;
      height: 1.25em;
      margin-top: -0.1em;
    }
  }
}
</style>
