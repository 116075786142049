<template>
  <div class="navbar-login">
    <BButton
      v-if="!drupalUser.isLoggedIn"
      pill
      variant="primary"
      size="sm"
      :to="{ name: 'login', query: { prefill: 1 } }"
    >
      <PersonIcon /> <span>{{ linkLabel }}</span>
    </BButton>
    <a v-else class="btn btn-sm btn-danger rounded-pill" :href="loginUrl">{{
      $texts('logout', 'Abmelden')
    }}</a>
  </div>
</template>

<script setup lang="ts">
// https://icones.js.org/collection/bi?s=person
import PersonIcon from '~icons/bi/person'

const linkLabel = computed(() => {
  // Todo if logged in it should be logout
  return 'Login'
})

const drupalUser = useDrupalUser()
const route = useRoute()
const router = useRouter()
const language = useCurrentLanguage()
const { $texts } = useNuxtApp()

const loginUrl = computed<string>(() => {
  // If the user is logged in, the link points to the logout page.
  if (drupalUser.value.isLoggedIn) {
    // Links to the logout page, with a nested destination parameter.
    return router.resolve({
      path: `/${language.value}/user/logout`,
    }).href
  }

  // Anonymous users: Link to login page.
  return router.resolve({
    name: 'login',
    query: {
      destination: route.path,
    },
  }).href
})
</script>

<style lang="scss" scoped>
@import '~/assets/scss/variables';
.navbar-login {
  padding-right: 1rem;

  @include media-breakpoint-up(lg) {
    padding-right: 0.25rem;
  }

  .btn {
    min-width: 40px;
    height: 40px;
    padding: 0 0.75em;
    display: flex;
    align-items: center;

    span {
      display: none;
      @include media-breakpoint-up(xl) {
        display: inline;
      }
    }

    svg {
      width: 1.25em;
      height: 1.25em;
      margin-top: -0.1em;
    }
  }
}
</style>
