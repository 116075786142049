<template>
  <div>
    <ClientOnly>
      <Notifications />
      <div v-if="drupalUser.accessToolbar" class="d-none d-md-block">
        <VuepalAdminToolbar :key="language" />
        <div class="local-tasks-wrapper">
          <div class="container">
            <VuepalLocalTasks />
          </div>
        </div>
      </div>
    </ClientOnly>

    <PageHeader />

    <NuxtPageDependency>
      <Breadcrumb :links="breadcrumb" />
    </NuxtPageDependency>

    <ClientOnly>
      <NuxtLoadingIndicator :throttle="0" :color="config.public.loading" />
    </ClientOnly>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <Footer />
  </div>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()
const breadcrumb = useBreadcrumbLinks()
const drupalUser = useDrupalUser()
const language = useCurrentLanguage()

useHead({
  htmlAttrs: {
    lang: language.value,
  },
})
</script>
