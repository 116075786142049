<template>
  <div class="navbar">
    <Container>
      <PageHeaderLogo />
      <div class="navbar-main" :class="{ 'has-menu-open': !!firstLevel }">
        <Menu
          v-model="firstLevel"
          :menu-links="menuLinks"
          :visible="menuVisible"
          :is-mobile="isMobile"
        />
        <PageHeaderBible v-if="hasBible" />
        <PageHeaderSearch
          :is-open="firstLevel === 'search'"
          @open="firstLevel = 'search'"
        />
        <PageHeaderLogin v-if="canLogin" />
        <button
          class="navbar-toggle"
          :class="{ 'is-active': menuOpen }"
          @click="menuOpen = !menuOpen"
        >
          <SpriteSymbol name="menu" />
          <span>Menu</span>
        </button>
      </div>
    </Container>
    <transition name="fade">
      <div
        v-if="backgroundVisible"
        class="navbar-background"
        @click="onBackgroundClick"
      ></div>
    </transition>
  </div>
</template>

<script setup lang="ts">
const menuOpen = ref(false)
const firstLevel = ref('')

const initData = await useInitData()
const menuLinks = initData.value.mainMenuLinks

const { name } = useCurrentSite()
const route = useRoute()

const canLogin = computed(() => {
  return name === 'haltefest'
})
const hasBible = computed(() => {
  return name === 'eb'
})

const { isMobile, updateValue: updateMobile } = useIsMobile()

/**
 * On desktop, menu is always visible. On mobile, only when it has been
 * opened via toggle.
 */
const menuVisible = computed(() => {
  return !isMobile.value || menuOpen.value
})
/**
 * When the background should be visible.
 *
 * There are multiple combinations on when it should be visible, it's
 * different between mobile and desktop.
 */
const backgroundVisible = computed(() => {
  return (
    (isMobile.value && menuOpen.value) ||
    (!isMobile.value && firstLevel.value) ||
    firstLevel.value === 'search'
  )
})

// https://github.com/nuxt/nuxt/issues/23075
// This code makes sure, that the navigation closed on a click on level 2
watch(
  () => route.fullPath,
  () => {
    if (isMobile.value) {
      menuOpen.value = false
    } else {
      firstLevel.value = ''
    }
  },
)

const onBackgroundClick = () => {
  menuOpen.value = false
  if (!isMobile.value || firstLevel.value === 'search') {
    firstLevel.value = ''
  }
}
const onResize = () => {
  const smallScreen = window.innerWidth < 1100
  if (smallScreen !== isMobile.value) {
    updateMobile(smallScreen)
  }
}
onMounted(() => {
  if (import.meta.client) {
    window.addEventListener('resize', onResize)
    onResize()
  }
})
onBeforeUnmount(() => {
  if (import.meta.client) {
    window.removeEventListener('resize', onResize)
  }
})
</script>

<style lang="scss">
@import '~/assets/scss/variables';
.navbar-search {
  .btn {
    background-color: $gray-400;
    border-color: $gray-400;
    color: $gray-700;
    margin-right: 5px;
  }
}
</style>
