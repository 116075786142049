import { defineNuxtPlugin } from '#app/nuxt'
import { LazyRenderCacheable, LazyNuxtPageDependency, LazyVuepalLocalTasks, LazyVuepalAdminToolbar, LazyVuepalRemoteVideo, LazyVuepalLink, LazyVuepalTransitionHeight, LazySpriteSymbol } from '#components'
const lazyGlobalComponents = [
  ["RenderCacheable", LazyRenderCacheable],
["NuxtPageDependency", LazyNuxtPageDependency],
["VuepalLocalTasks", LazyVuepalLocalTasks],
["VuepalAdminToolbar", LazyVuepalAdminToolbar],
["VuepalRemoteVideo", LazyVuepalRemoteVideo],
["VuepalLink", LazyVuepalLink],
["VuepalTransitionHeight", LazyVuepalTransitionHeight],
["SpriteSymbol", LazySpriteSymbol],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
