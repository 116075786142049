<template>
  <transition-group
    enter-active-class="notification-start"
    enter-from-class="transform translate-y-full opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="notification-end"
    leave-to-class="transform translate-y-full opacity-0"
  >
    <Message
      v-for="(message, i) in messages"
      :key="i"
      v-bind="message"
      class="notification"
      @click="removeMessage(i)"
      @close="removeMessage(i)"
    />
  </transition-group>
</template>

<script lang="ts" setup>
const { messages, removeMessage } = useDrupalMessages()
</script>

<style lang="scss">
.notification-start {
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transform-origin: top;
}

.notification-end {
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transform-origin: top;
}

.translate-y-full {
  --transform-translate-y: 100%;
}
.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}
</style>
